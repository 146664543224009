import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CountUp, { useCountUp } from 'react-countup';
import ReactGA from "react-ga4";

import Aos from 'aos';
import 'aos/dist/aos.css';
import { Link, useLocation } from 'react-router-dom';
import Footer from '../Components/Footer';

const Home = () => {
useEffect(function () {
Aos.init({ disable: 'mobile' });
}, []);

useCountUp({
  ref: 'counter',
  end: 1234567,
  enableScrollSpy: true,
  scrollSpyDelay: 2000,
});

const [totalSupply, setTotalSupply] = useState<number | null>(null);
  const [currentPrice, setCurrentPrice] = useState<number | null>(null);
  const [marketCap, setMarketCap] = useState<string | null>(null);

  useEffect(() => {

    const fetchCoinData = async () => {
      try {
        // Fetching the total supply
        const totalSupplyResponse = await axios.get('https://api.dexscreener.com/latest/dex/pairs/scroll/0x8443795318eb88573E1eF73c00E79681231AA40e');
        const totalSupplyData = totalSupplyResponse.data.pair.fdv;
      console.error(totalSupplyResponse);
        setTotalSupply(totalSupplyData);

        // Fetching the current price
        // const priceResponse = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=skydrome&vs_currencies=usd');
        // const priceData = priceResponse.data;
        // setCurrentPrice(priceData.skydrome.usd);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCoinData();

  }, []);

    useEffect(() => {
      if (totalSupply !== null) {
        let marketCapValue = totalSupply;
        let formattedMarketCap: string;
  
        if (marketCapValue >= 1e9) {
          formattedMarketCap = (marketCapValue / 1e9).toFixed(2) + 'B';
        } else if (marketCapValue >= 1e6) {
          formattedMarketCap = (marketCapValue / 1e6).toFixed(2) + 'M';
        } else if (marketCapValue >= 1e3) {
          formattedMarketCap = (marketCapValue / 1e3).toFixed(2) + 'K';
        } else {
          formattedMarketCap = marketCapValue.toFixed(2);
        }
  
        setMarketCap(formattedMarketCap);
      }
    }, [totalSupply]);

// const location = useLocation();

// useEffect(() => {
// window.scrollTo(0, 0);
// }, [location]);

return (
<>
  {/* Banner */}
  <div className="top-banner">
    <section className="banner" id="home">
      <div className="container py-lg-5">
        <div className="row">
          <div className="col-md-6 mb-md-0 mb-">
            <h1 className="" data-aos="fade-right" data-aos-duration="800">
              The leading ve(3,3) exchange on
              <img src="assets/scroll.png" className='ps-2' height={42} alt="" />
            </h1>
            <p className="pt-4" data-aos="fade-right" data-aos-duration="1200">
              Skydrome is a decentralized exchange (DEX) and liquidity marketplace on the Scroll blockchain.
            </p>
            <div className="pt-md-4" data-aos="fade-right" data-aos-duration="1500">
              <div className="d-inline-flex flex-md-row flex-column gap-3 mb-lg-5 mb-4">
                <a href="https://app.skydrome.finance" target={'_blank'} onClick={() => {
                  ReactGA.event({
                    category: 'click',
                    action: 'Buy SKYDROME Click',
                  });
                }} className="gap-2 primary-btn btn-1" rel="noreferrer">
                  Buy on <img src="assets/sky.svg" alt="" />
                </a>
                <a href="https://www.mexc.com/exchange/SKYDROME_USDT" target={'_blank'} onClick={() => {
                  ReactGA.event({
                    category: 'click',
                    action: 'Buy MEXC Click',
                  });
                }} rel="noreferrer" className="btn-1 px-lg-5 gap-2 primary-btn-outline">
                  Buy on <img src="assets/l1.svg" height={15} alt="" />
                </a>
              </div>

            </div>
          </div>
          <div className="col-md-6 ps-lg-5" data-aos="fade-left" data-aos-duration="2000">
            <img src="assets/banner.png" className="w-100" alt="" />
          </div>
        </div>
      </div>

      <div className="container pb-5"  data-aos="fade-up" data-aos-duration="1300">
        <div className="partner-list" >
          <div className="scroll-x">
          <h3 className="d-md-none d-block">Featured in</h3>
            <div className="d-flex flex-wrap align-items-center pt-lg-3">
              <h3 className="col-20 d-md-block d-none">Featured in</h3>
              <div className="col-20 text-center">
                <img src="assets/fr3.png" className="logo-3" alt="" />
              </div>
              <div className="col-20 text-center w-5">
                <img src="assets/fr4.png" className="logo-4" alt="" />
              </div>
              <div className="col-20 text-center">
                <img src="assets/fr1.png" className="logo-1" alt="" />
              </div>
              <div className="col-20 text-center">
                <img src="assets/fr2.png" className="logo-2" alt="" />
              </div>
              <div className="col-20 text-center">
                <img src="assets/fr5.png" className="logo-5" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="partner-list pt-lg-3">
          <div className="scroll-x">
          <h3 className="d-md-none d-block">Listed on</h3>
            <div className="d-flex flex-wrap align-items-center pt-lg-3">
              <h3 className="col-20 d-md-block d-none">Listed on</h3>
              <div className="col-20 text-center">
                <a className='listed-link' href="https://www.mexc.com/exchange/SKYDROME_USDT" target="_blank" onClick={() => ReactGA.event({ category: 'click', action: 'Buy MEXC Click - Listed On' })} rel="noreferrer">
                  <img src="assets/l1.svg" className="logo-6" alt="" />
                </a>
              </div>
              <div className="col-20 text-center">
                <a className='listed-link' href="https://www.coingecko.com/en/coins/skydrome" target="_blank"  rel="noreferrer">
                  <img src="assets/l2.svg" className="logo-7" alt="" />
                </a>
              </div>
              <div className="col-20 text-center">
                <a className='listed-link' href="https://dexscreener.com/scroll/0x8443795318eb88573e1ef73c00e79681231aa40e" target="_blank"  rel="noreferrer">
                  <img src="assets/l3.svg" className="logo-8" alt="" />
                </a>
              </div>
              <div className="col-20 text-center">
                <img src="assets/l4.svg" className="logo-9" alt="" />
              </div>
              <div className="col-20 text-center">
                <a className='listed-link' href="https://defillama.com/protocol/skydrome" target="_blank"  rel="noreferrer">
                  <img src="assets/l5.svg" className="logo-10" alt="" />
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="container" data-aos-duration="1800">
        <div className="row">
          <div className="col-md-12 mb-4 pb-lg-4">
            <h2 data-aos="fade-up" >Skydrome in Numbers</h2>
            
          </div>
          <div className="col-md-4 mb-4 mb-lg-5" data-aos="fade-right" data-aos-duration="1400">
                  <div className="d-flex align-items-center gap-3 mb-3">
                    <img src="assets/1.svg" alt="" />
                    <div>
                    <h4 className='mb-1'>Market Cap</h4>
                    {marketCap && (
                    <h2 className='primary-text'>${marketCap}</h2>
      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3 mb-4 mb-lg-5" data-aos="fade-up" data-aos-duration="1800">
                  <div className="d-flex align-items-center gap-3 mb-3">
                    <img src="assets/2.svg" alt="" />
                    <div>
                    <h4 className='mb-1'>Holders</h4>
                    <h2 className='primary-text'><CountUp end={10} enableScrollSpy />k+</h2>
                    </div>
                  </div>
                </div>

                <div className="col-md-5 mb-4 mb-lg-5 ps-lg-4" data-aos="fade-left" data-aos-duration="1600">
                  <div className="d-flex align-items-center gap-3 mb-3">
                    <img src="assets/3.svg" alt="" />
                    <div>
                    <h4 className='mb-1'>Mainnet Transactions</h4>
                    <h2 className='primary-text'><CountUp end={400} enableScrollSpy />k+</h2>
                    </div>
                  </div>
                </div>
        </div>
      </div>
    </section>

    <section className="feature-bg pb-md-5 mb-lg-4">

      <div className="container py-5">
        <div className="row">
          <div className="col-md-6 mb-md-0 mb-4" data-aos="fade-right" data-aos-duration="1800">
            <h2>ve (3,3) Incentives + Liquidity Flywheel</h2>
          </div>
          <div className="col-md-6" data-aos="fade-left" data-aos-duration="1800">
            <p className='mb-md-4'>Skydrome’ custom implementation takes a unique approach to pairing incentive
              multipliers with liquidity concentration to achieve next-level capital efficiency.
              This disruptive method produces the lowest slippage currently feasible in DeFi.</p>
            <a href="https://app.skydrome.finance" target={"_blank"} rel="noreferrer" className='primary-btn px-lg-5 hy-40'>Open App</a>
          </div>
        </div>
      </div>

      <div className="border-top border-white"></div>

      <div className="container pt-lg-2" id="features">
        <div className="row py-5 mb-lg-5">
          <div className="col-md-3 mb-md-0 mb-4">
            <div className="h-100 bg-white br-10 px-3 py-4" data-aos="fade-up" data-aos-duration="1600">
              <div>
                <img src="assets/ic-1.svg" alt="" />
                <h4 className="mt-4">Low Slippage</h4>
                <p>Skydrome houses deep liquidity, low fees, and near 0 slippage when swapping correlated assets.</p>
              </div>
            </div>
          </div>

          <div className="col-md-3 mb-md-0 mb-4">
            <div className="h-100 bg-white br-10 px-3 py-4" data-aos="fade-up" data-aos-duration="2000">
              <div>
                <img src="assets/ic-2.svg" alt="" />
                <h4 className="mt-4">Novel ve(3,3)Tokenomics</h4>
                <p>Inspired by Andre Cronje’s Solidly, veSKY takes a novel approach on the
                  voting-escrow model. Skydrome has an exponential decay programmed within the core,
                  ensuring a sustainable token model for years to come. The SKY model rewards
                  long-term supporters, and aligns stakeholders interests by incentivizing fee generation.</p>
              </div>
            </div>
          </div>

          <div className="col-md-3 mb-md-0 mb-4">
            <div className="h-100 bg-white br-10 px-3 py-4" data-aos="fade-up" data-aos-duration="2400">
              <div>
                <img src="assets/ic-3.svg" alt="" />
                <h4 className="mt-4">Community-Owned Protocol</h4>
                <p>Skydrome has a decentralized Token Generation Event (TGE) that welcomes regular users within the
                  Skydrome ecosystem, and supports protocols looking to build liquidity.</p>
              </div>
            </div>
          </div>

          <div className="col-md-3 mb-md-0 mb-4">
            <div className="h-100 bg-white br-10 px-3 py-4" data-aos="fade-up" data-aos-duration="2800">
              <div>
                <img src="assets/ic-4.svg" alt="" />
                <h4 className="mt-4">Low Fee Hybrid AMM</h4>
                <p>With default fees ranging from as low as 0.02% for correlated pools,
                  to 0.25% for volatile pools, Skydrome gives traders the benefit of low
                  fee swaps, and negligible slippage on closely correlated assets.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-lg-5" id="testimonial">
        <div className="row">
          <div className="col-md-12 mb-4" data-aos="fade-up" data-aos-duration="1300">
            <h2>Testimonials from the community</h2>
          </div>
          <div className="col-md-4 mb-4">
            <div className="user-box" data-aos="fade-up" data-aos-duration="1500">
              <div className="mb-2">
                <img src="assets/quote.svg" className="mb-4" alt="" />
                <p>
                Number one native DEX by TVL on Scroll, went from 0 to 1.5 Mil TVL in 2 weeks. Great utility 
                (locking means trading fees revenue stream). Audited by DefiMoon and SolidProof.
                </p>
              </div>
              <div className="user">
                <div className="user-img">
                  <img src="assets/user.png" alt="" />
                </div>
                <div className="user-detail">
                  <h6 className="mb-0">leochain <img src="assets/success.svg" alt="" /></h6>
                  <p className="mb-1">@leochain0</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="user-box" data-aos="fade-up" data-aos-duration="2000">
              <div className="mb-2">
                <img src="assets/quote.svg" className="mb-4" alt="" />
                <p>
                Inspired by the Velodrome model, Skydrome boasts a powerful liquidity incentivization system, 
                a governance model driven by vote-locking, and a user-friendly interface that's a breeze to navigate.
                </p>
              </div>
              <div className="user">
                <div className="user-img">
                  <img src="assets/user1.png" alt="" />
                </div>
                <div className="user-detail">
                  <h6 className="mb-0">DORAMTS <img src="assets/success.svg" alt="" /></h6>
                  <p className="mb-1">@Matrixthesun</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="user-box" data-aos="fade-up" data-aos-duration="2500">
              <div className="mb-2">
                <img src="assets/quote.svg" className="mb-4" alt="" />
                <p>
                Here's why I'm bullish on Skydrome and Scroll: Skydrome's Ve (3,3) features 
                will increase the price once launched. Despite not having its own token, Scroll 
                has the potential to be the next zkSync. If Scroll succeeds, Skydrome's native DEX token will surge too.      
                </p>
              </div>
              <div className="user">
                <div className="user-img">
                  <img src="assets/user2.png" alt="" />
                </div>
                <div className="user-detail">
                  <h6 className="mb-0">Spell <img src="assets/success.svg" alt="" /></h6>
                  <p className="mb-1">@CryptoSpelll</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5">
        <div className="row align-items-md-center">
          <div className="col-md-6 pe-lg-5 mb-md-0 mb-4">
            <h2 className="mb-4" data-aos="fade-up" data-aos-duration="1600">Explore Liquidity Pools</h2>
            <div className="pe-lg-5">
              
              
              
              <div className="nav flex-column gap-3 nav-pills" data-aos="fade-left" data-aos-duration="2000" id="v-pills-tab" role="tablist" aria-orientation="vertical">
    <button className="nav-link active" id="v-pills-home-tab" 
     data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
    <div className="metric-box" >
                <h4>Fees</h4>
                <p>
                Skydrome operates with a unique approach to trading fees.
                </p>
              </div>
              </button>
    <button className="nav-link" id="v-pills-profile-tab" 
    data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
    <div className="metric-box" >
                <h4>Stable Pools</h4>
                <p>
                Stable pools are specifically designed for asset pairs that has similar volatility profiles.
                </p>
              </div>
              </button>
    <button className="nav-link" id="v-pills-messages-tab" 
    data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
    <div className="metric-box" >
                <h4>Volatile Pools</h4>
                <p>
                Volatile pools are for asset pairs with prices that are free-floating.
                </p>
              </div>
              </button>
  </div>
            </div>
          </div>
          <div className="col-md-6 pool pb-lg-5" data-aos="fade-right" data-aos-duration="1800">
      <img src="assets/pool.png" className='w-100 mx-350' alt="" />
          <div className="tab-content mb-md-4" id="v-pills-tabContent">
    <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
      <p>Skydrome offers custom configurable pools to accommodate all pair types: Stable or Volatile.</p>
    </div>
    <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
      <p>Discover the freedom to tailor your trading experience with Skydrome's innovative stable pools, designed specifically for assets with similar price ranges. 
        Enjoy the benefits of customized price curve, reduced slippage, optimized pricing, and a smoother transaction process that caters to your needs.</p>
    </div>
    <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
    <p className='' >Explore the dynamic world of volatile pools, designed to accommodate asset pairs with free-floating prices. Skydrome empowers
     you to take advantage of these ever-changing market conditions, offering unparalleled flexibility and endless trading possibilities.</p>
    </div>
  </div>
            <a href="https://docs.skydrome.finance/" target={'_blank'} className='primary-btn px-lg-5 hy-40'><span className="px-lg-3">Docs</span></a>
            
          </div>

        </div>
      </div>
    </section>


    <section className="my-5 pb-lg-5">
      <div className="container">
        <div className="partner-list">
          <h2 data-aos="fade-up" className="mb-lg-4 mb-3" data-aos-duration="1300">
            Partners
          </h2>
          <div className="box" data-aos="fade-up" data-aos-duration="1800">
          <div className="row justify-content-center align-items-center pt-lg-3 partners"
            data-aos="fade-up" data-aos-duration="2000">
            <div className="col-md-3 col-6 text-center mb-4">
              <img src="assets/p1.svg" className="" alt="" />
            </div>
            <div className="col-md-2 col-6 text-center mb-4">
              <img src="assets/p2.svg" className="w-sm-100" alt="" />
            </div>
            <div className="col-md-3 col-6 text-center mb-4">
              <img src="assets/p3.svg" className="w-sm-100" alt="" />
            </div>
            <div className="col-md-3 col-6 text-center mb-4">
              <img src="assets/p4.svg" className="w-sm-100" alt="" />
            </div>
            <div className="col-md-3 col-6 text-center mb-4">
              <img src="assets/p5.png" className="w-100 px-lg-4"  alt="" />
            </div>
            <div className="col-md-3 col-6 text-center mb-4">
              <img src="assets/p6.svg" className="w-sm-100"  alt="" />
            </div>
            <div className="col-md-3 col-6 text-center mb-md-4">
              <img src="assets/p7.png" className="w-100" alt="" />
            </div>
            <div className="col-md-3 col-6 text-center mb-md-4">
              <img src="assets/p8.png" className="w-100" alt="" />
            </div>
           
          </div>
          </div>
        </div>
      </div>
    </section>


    <section>
      <div className="container">
      <div className="row my-md-5">
        <div className="col-md-12">
        <h2 data-aos="fade-up" className="mb-lg-4 mb-3" data-aos-duration="1300">
        Mechanics
          </h2>
        </div>
      <div className="col-md-6 mb-4">
      <div className="sky-card" data-aos="fade-right" data-aos-duration="1800">
        <div className="sky-detail">
          <h3>Vote:</h3>
          <p>1 - Lock SKY to create veSKY.</p>
          <p>2 - Use veSKY to vote for your favorite pools.</p>
          <p>3 - Earn weekly rewards.</p>
          </div>
          <div className="img-box">
            <img src="assets/vote.png" className="" alt=""/>
          </div>
        </div>
      </div>

      <div className="col-md-6 mb-4">
      <div className="sky-card" data-aos="fade-left" data-aos-duration="2200">
        <div className="sky-detail">
          <h3>LP Stake:</h3>
          <p>1 - Provide liquidity for your desired pools.</p>
          <p>2 -  Earn weekly rewards in SKY.</p>
          </div>
          <div className="img-box">
          <img src="assets/stake.png" className="" alt=""/>
          </div>
        </div>
      </div>

    </div>
      </div>
    </section>


    <section className="featured" id="articles">
      <div className="container">
        <div className="mb-4 pb-2" data-aos="fade-up" data-aos-duration="1000">
          <h2 className="mb-0">Blog</h2>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-lg-0 mb-4" data-aos="fade-right" data-aos-duration="1200">
            <a href="javascript:void(0)" target="_blank" rel="noopener noreferrer" className="feature-card">
              <a className="feature-img" href="https://medium.com/@Skydrome/how-to-add-liquidity-on-skydrome-2532692a6436" target="_blank"><img src="assets/b1.png" className="w-100" alt="" /></a>
              <div className="feature-detail">
                <p className="light-text">Oct 11, 2023</p>
                <h3>How to Add Liquidity On Skydrome</h3>
                <div><a href="https://medium.com/@Skydrome/how-to-add-liquidity-on-skydrome-2532692a6436" target="_blank" rel="noopener noreferrer" className='primary-btn px-lg-5 hy-40'>Learn More</a></div>
              </div>
            </a>
          </div>

          <div className="col-lg-4 col-md-6 mb-lg-0 mb-4" data-aos="fade-up" data-aos-duration="1600">
            <a href="javascript:void(0)" target="_blank" rel="noopener noreferrer" className="feature-card">
              <a className="feature-img" href="https://medium.com/@Skydrome/how-to-bridge-eth-to-scroll-mainnet-3606fcf36f91" target="_blank"><img src="assets/b2.png" className="w-100" alt="" /></a>
              <div className="feature-detail">
                <p className="light-text">Oct 11, 2023</p>
                <h3>How to Bridge ETH to Scroll Mainnet</h3>
                <div><a href="https://medium.com/@Skydrome/how-to-bridge-eth-to-scroll-mainnet-3606fcf36f91" target="_blank" rel="noopener noreferrer" className='primary-btn px-lg-5 hy-40'>Learn More</a></div>
              </div>
            </a>
          </div>

          <div className="col-lg-4 col-md-6" data-aos="fade-left" data-aos-duration="1200">
            <a href="hjavascript:void(0)" target="_blank" rel="noopener noreferrer" className="feature-card">
              <a className="feature-img" href="https://medium.com/@Skydrome/how-to-get-sepolia-eth-from-faucets-3ea3660142c0" target="_blank"><img src="assets/b3.png" className="w-100" alt="" /></a>
              <div className="feature-detail">
                <p className="light-text">Sep 29, 2023</p>
                <h3>How To Get Sepolia ETH From Faucets</h3>
                <div><a href="https://medium.com/@Skydrome/how-to-get-sepolia-eth-from-faucets-3ea3660142c0" target="_blank" rel="noopener noreferrer" className='primary-btn px-lg-5 hy-40'>Learn More</a></div>
              </div>
            </a>
          </div>

        </div>
      </div>
    </section>


    <div className="bg-3">
    <section className="faq">
      <div className="container">
        <div className="row justify-content-center py-5 my-lg-5">
            <h2 data-aos="fade-up" data-aos-duration="1200">FAQs</h2>

            <div className="box-faq">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item" data-aos="fade-up" data-aos-duration="1300">
                  <h3 className="accordion-header" id="headingOne">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                      What is Skydrome?
                    </button>
                  </h3>
                  <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Skydrome is a decentralized exchange (DEX) and liquidity marketplace
                      within the Scroll blockchain, enabling users and DAOs to engage in
                      diverse actions within the protocol’s ecosystem by incorporating a
                      sophisticated ve(3,3) structure. At its core, Skydrome is more than
                      just a decentralized exchange (DEX). It’s a thriving ecosystem within
                      the Scroll blockchain, fueled by innovation and community-driven power.
                      Within the Skydrome ecosystem, the ve(3,3) framework serves as an incentivization
                      module, rewarding participants who contribute to the platform’s growth.
                      These contributions include providing liquidity and holding tokens over
                      extended periods. Notably, this unique tokenomics design draws inspiration
                      from two influential DeFi projects: Curve Finance and OlympusDAO.
                    </div>
                  </div>
                </div>

                <div className="accordion-item" data-aos="fade-up" data-aos-duration="1500">
                  <h3 className="accordion-header" id="heading2">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                      Why Skydrome is Built on Scroll?
                    </button>
                  </h3>
                  <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      A groundbreaking Layer-2 solution, Scroll focuses on achieving limitless scalability,
                      ultra-fast confirmation times, complete decentralization, and privacy without reliance
                      on trust. Scroll Tech’s mission is to construct an EVM-compatible zk-Rollup with a robust
                      proving network. By enabling direct Ethereum block verification through succinct proofs,
                      Scroll ensures opcode consistency in EVM execution traces. This smooth transition for
                      Layer-1 (L1) contracts utilizes Ethereum’s native EVM support while ensuring compatibility
                      with the Ethereum infrastructure.
                    </div>
                  </div>
                </div>

                <div className="accordion-item" data-aos="fade-up" data-aos-duration="1500">
                  <h3 className="accordion-header" id="heading3">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                      Could you provide an explanation of ve(3,3) mechanics?
                    </button>
                  </h3>
                  <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      Skydrome incorporates the intricate ve(3,3) structure. Within the Skydrome ecosystem, the ve(3,3)
                      framework serves as an incentivization module, rewarding efforts that enhance the platform’s
                      standing. These efforts include liquidity provision and long-term token holding. This unique
                      tokenomics design combines elements from two influential DeFi projects: Curve Finance and
                      OlympusDAO. <br /> <br />

                      Traditional decentralized exchanges (DEXs) often incentivize liquidity providers (LPs) with native
                      tokens. However, this approach has some challenges like dilution risk, lack of protocol loyalty
                      and imbalance liquidity.  <br /> <br />

                      Ve(3,3) mechanics combine two key components: <br /> <br />

                      1. Vote-Escrow Tokenomics (‘ve’): Inspired by Curve Finance, this system encourages long-term token
                      retention. <br /> <br />
                       2. (3,3) Game Theory: OlympusDAO pioneered this approach, using protocol-owned liquidity to
                      enhance LP token yields. <br /> <br />

                      ve(3,3) in Skydrome: <br /> <br />
                      <ul>
                      <li> Within the Skydrome ecosystem, ve(3,3) serves as an incentivization module.</li>
                      <li>It rewards endeavors that boost the platform’s stature, such as liquidity contribution and
                      sustained token holding. </li>
                      <li> Liquidity providers receive emissions, while veSKY holders benefit from protocol charges, rebases,
                      and governance participation.</li>

                      </ul>
                    </div>
                  </div>
                </div>

                <div className="accordion-item" data-aos="fade-up" data-aos-duration="1600">
                  <h3 className="accordion-header" id="heading4">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                      Has Skydrome undergone an audit?
                    </button>
                  </h3>
                  <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      In our commitment to user security, we’ve conducted additional audits with DefiMoon and SolidProof
                      to verify the safety of all contracts.
                    </div>
                  </div>
                </div>

                <div className="accordion-item" data-aos="fade-up" data-aos-duration="1700">
                  <h3 className="accordion-header" id="heading5">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                      Is Skydrome listed on any cryptocurrency exchange (CEX)?
                    </button>
                  </h3>
                  <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      It is listed on MEXC and will be listed on one of the CEXs soon. Stay tuned!
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
    </section>

    <section >
      <div className="container">
          <div className="col-md-12" data-aos="fade-up" data-aos-duration="1500">
            <h2 className="mb-4">Our audit partners</h2>
          </div>

          <div className="box" data-aos="fade-up" data-aos-duration="1800">
              <div className="row justify-content-center py-4 pt-lg-5">
                <div className="col-md-4 col-10 mb-lg-0 mb-4 text-center">
                  <a href="https://github.com/SkyDromeFinance/audits/blob/main/audits/Skydrome_Defimoon.pdf" target={'_blank'}>
                  <img src="assets/defi.svg" className='mb-3' alt="" />
                  <h4>DefiMoon</h4>
                  </a>
                </div>
                <div className="col-md-4 col-10 text-center bx-1">
                  <a href="https://github.com/SkyDromeFinance/audits/blob/main/audits/SmartContract_Audit_Solidproof_Skydrome.pdf" target={'_blank'}>
                  <img src="assets/solid.svg" className='mb-3' alt="" />
                  <h4>SolidProof</h4>
                  </a>
                </div>
                {/* <div className="col-md-4 col-10 text-center">
                <a href="https://www.coinscope.co/coin/8-sky" target={'_blank'}>
                <img src="assets/cyber.png" className='mb-3' height={65} alt="" />
                  <h4>Cyberscope KYC</h4>
                  </a>
                </div> */}
              </div>
          
        </div>
      </div>
      <Footer />
    </section>
  </div>
  </div>
</>
);
};

export default Home;
