import React from "react";

const Footer = () => {

  return (
    <>
      <footer>

        <div className="container py-4 border-bottom">
          <div className="row align-items-center">
            <div className="col-md-4 mb-md-0 mb-5">
            <div className="d-flex flex-md-row flex-column align-items-md-center gap-4 text-center text-md-start">
              <span><img src="assets/logo.svg" className="light-logo" alt="" /></span>
            </div>
            </div>

            <div className="col-md-8">
            <div className="d-flex flex-md-row flex-column justify-content-md-end justify-content-center align-items-center gap-4">
              
              <div className="d-flex justify-content-md-end align-items-center gap-4">
                <a href="https://github.com/SkyDromeFinance" target={"_blank"} className="footer-ic" rel="noreferrer"><span><img src="assets/git.svg" alt="" /></span></a>
                <a href="https://twitter.com/skydrome" target={"_blank"} className="footer-ic" rel="noreferrer"><span><img src="assets/x.svg" alt="" /></span></a>
                <a href="https://discord.gg/ZbE69CARXm" target={"_blank"} className="footer-ic" rel="noreferrer"><span><img src="assets/discord.svg" alt="" /></span></a>
                <a href="https://discord.gg/ZbE69CARXm" target={"_blank"} className="primary-btn-outline" rel="noreferrer">
                Join community
              </a>
              </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container bt-1 py-4">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5 order-md-0 order-1 text-md-start text-center"><p className="fs-14 mb-0 dark-text">Copyright 2024 SKYDROME. All rights reserved.</p></div>
            <div className="col-md-6 mb-md-0 mb-4">
            <div className="d-flex flex-md-row flex-column justify-content-md-end justify-content-center align-items-center gap-4">
              <div className="d-flex gap-4">
              <a href="https://medium.com/@Skydrome" target="_blank" className="footer-link">Blog</a>
              <a href="https://docs.skydrome.finance/" target="_blank" rel="noopener noreferrer" className="footer-link">Docs</a>
              </div>
              <div className="d-flex gap-4">
              <a href="https://app.skydrome.finance/terms" target="_blank" rel="noopener noreferrer" className="footer-link">Terms</a>
              <a href="https://docs.skydrome.finance/security/privacy-policy" target="_blank" rel="noopener noreferrer" className="footer-link">Privacy Policy</a>
              <a href="https://docs.skydrome.finance/security/legal-disclaimer" target="_blank" rel="noopener noreferrer" className="footer-link">Legal Disclaimer </a>
              </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

    </>
  );
};

export default Footer;
