import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

const Header = () => {
  const [stickyClass, setStickyClass] = useState("relative");
  const homeNav = () => {
    if (document.body.classList.contains("home-sm")) {
      document.body.classList.remove("home-sm");
    } else {
      document.body.classList.add("home-sm");
    }
  };

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 50
        ? setStickyClass("bg-pink")
        : setStickyClass("");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  return (
    <div>
      <nav className={`navbar navbar-expand-lg transition-all pb-lg-0  ${stickyClass}`}>
        <div className="container  py-lg-2">
          <Link to="/" className="navbar-brand">
          <img src="assets/favicon.svg" className="d-md-none d-inline-block" alt="" />
            <span className="d-md-inline-block d-none"><img src="assets/logo.svg" className="light-logo" alt="" />
            <img src="assets/logo-dark.svg" className="dark-logo" alt="" /></span>
          </Link>
          <div className="d-lg-none d-inline-flex gap-2 align-items-center">
          <a href="https://app.skydrome.finance" target={"_blank"} rel="noreferrer" className="primary-btn d-lg-none d-flex h-auto px-3 fs-14">
          Open App
              </a>
              <button className="navbar-toggler" type="button" onClick={() => {homeNav();}}>
            <img src="assets/burger.svg" className="" alt="" />
            </button>
          </div>

          <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <div className="d-lg-none d-flex justify-content-between text-end p-4">
              <Link to="/" className="navbar-brand">
                <img src="assets/favicon.svg" alt="" />
              </Link>
              <a href="javascript:void(0)" onClick={() => {homeNav();}} className="cross-btn">
                <span className="iconify" data-icon="akar-icons:cross"></span>
              </a>
            </div>
            <ul className="navbar-nav align-items-center me-auto px-4 ps-lg-5">
                <li className="nav-item"><a href="https://discord.gg/ZbE69CARXm" target={"_blank"} rel="noreferrer" className="nav-link" onClick={() => { homeNav();}}>Discord</a></li>
                <li className="nav-item"><a href="https://twitter.com/skydrome" target={"_blank"} rel="noreferrer" className="nav-link" onClick={() => { homeNav();}}>Twitter</a></li>
                <li className="nav-item"><a href="https://zealy.io/c/skydrome/questboard" target={"_blank"} rel="noreferrer" className="nav-link" onClick={() => { homeNav();}}>Zealy</a></li>
                <li className="nav-item"><a href="https://github.com/SkyDromeFinance" target={"_blank"} rel="noreferrer" className="nav-link" onClick={() => { homeNav();}}>GitHub</a></li>
                <li className="nav-item"><a href="https://docs.skydrome.finance" target={"_blank"} rel="noreferrer" className="nav-link" onClick={() => { homeNav(); }}>Docs</a></li>
                <li className="nav-item"><a href="https://medium.com/@Skydrome" target={"_blank"} rel="noreferrer" className="nav-link" onClick={() => { homeNav();}}>Medium</a></li>
                {/*<li className="nav-item"><a href="https://ido.skydrome.finance" target={"_blank"} rel="noreferrer" className="nav-link" onClick={() => { homeNav();}}>Pre-Sale</a></li>*/}
            </ul>
            <div className="d-flex pt-lg-0 pt-5 px-4 px-md-0">
            <a href="https://app.skydrome.finance" target={"_blank"} className="primary-btn">Open App</a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
